import "./AllSmallCapsOverride.css";
import { Frame, HorizontalStack, Icon, Navigation, TopBar } from "@shopify/polaris";
import { SectionProps } from "@shopify/polaris/build/ts/src/components/Navigation/components";
import { ItemProps, SubNavigationItem } from "@shopify/polaris/build/ts/src/components/Navigation/types";
import { CaretDownMinor, CircleRightMajor, HideMinor, LockMinor, ViewMajor } from "@shopify/polaris-icons";
import { SuperuserBadge } from "@smartrr/shared/components/SuperuserBadge";
import { adminRoutePrefix, appPrefixedHost } from "@smartrr/shared/constants";
import { AccountPlanStatusEnum, FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import {
  getFromLocalStorage,
  setInLocalStorage,
  unsetInLocalStorage,
} from "@smartrr/shared/utils/localStorageAccess";
import { SelectOrganizationModal } from "@vendor-app/app/_sharedComponents/SelectOrganizationModal";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { isAdminConfig } from "@vendor-app/utils/appChecks";
import { isInIFrame } from "@vendor-app/utils/isInIframe";
import { doOpenStandaloneInNewTab } from "@vendor-app/utils/shopify/doOpenStandaloneInNewTab";
import cx from "classnames";
import { capitalize } from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import {
  isFeatureAvailable,
  isSuperUserSwitchDurationValid,
  updateSuperUserStatusRedux,
} from "../authorization/featureAccess";
import { Spinner } from "../elements/Spinner";
import "./AdminLayoutContainer.css";
import { loadUser, logout } from "@vendor-app/app/_state/actionCreators/initialize";
import { useHasTrialExpired } from "@vendor-app/utils/useHasTrialExpired";
import { useFilteredNavigationSection } from "./libs/hooks/useFilteredNavigationSection";

interface Props {
  children?: React.ReactNode;
}

export interface SubNavigationStructure extends SubNavigationItem {
  feature?: FeatureEnum;
}

export interface SectionStructure extends Omit<SectionProps, "items"> {
  items: (ItemProps & {
    isDefault?: boolean;
    feature?: FeatureEnum;
    locked?: boolean;
    subNavigationItems?: SubNavigationStructure[];
  })[];
}

const NavigationContainer = styled.div`
  &.smartrr-navigation-main-item
    .Polaris-Navigation__PrimaryNavigation
    .Polaris-Navigation__ItemInnerWrapper--selected {
    background-color: #fff;
    border-radius: 8px;

    & ::before {
      background-color: #3b1e33;
    }
  }

  &.smartrr-navigation-sub-item
    .Polaris-Navigation__SecondaryNavigation
    .Polaris-Navigation__ItemInnerWrapper--selected {
    border-radius: 8px;
    background-color: #fff;
  }

  & .Polaris-Navigation {
    background: #ebebeb;

    a:link,
    a:visited,
    a:hover,
    a:active {
      text-decoration: none;
    }

    svg * {
      fill: #5c5f62;
    }

    .Polaris-Navigation__ItemInnerWrapper--selected .Polaris-Navigation__SecondaryActions {
      background: transparent;
    }
    .Polaris-Navigation__SecondaryActions:last-child {
      margin-right: var(--p-space-050);
    }

    .Polaris-Navigation__Item,
    .Polaris-Navigation__ItemInnerWrapper {
      display: flex;
      align-items: center;
      border-top: 0;

      &:hover {
        & .Polaris-Navigation__SecondaryAction {
          background-color: transparent !important;
        }
      }

      .Polaris-Navigation__Text {
        color: #202223;
        font-weight: 600;
      }

      .Polaris-Navigation__Badge {
        margin: 0;
      }

      &:hover {
        background: #c4c4c419;
        .Polaris-Navigation__SecondaryAction {
          background: #c4c4c419;
        }
        .Polaris-Navigation__Text {
          color: #202223;
        }

        .Polaris-Navigation__Icon svg *,
        .Polaris-Navigation__Badge svg *,
        .Polaris-Navigation__SecondaryAction svg * {
          fill: #3b1e33;
        }
      }
    }

    .Polaris-Navigation--isExpanded {
      .Polaris-Navigation__Text {
        font-weight: 500;
      }

      .Polaris-Navigation__ItemInnerWrapper--selected {
        background: #3b1e33;
      }

      .Polaris-Navigation__ItemInnerWrapper--selected {
        .Polaris-Navigation__SecondaryAction svg * {
          fill: #3b1e33;
        }
      }

      .Polaris-Navigation__SecondaryAction {
        pointer-events: none;
        border-radius: 0;
        padding-right: 4px;
      }

      .Polaris-Navigation__SecondaryAction:focus {
        visibility: none;
      }

      .Polaris-Navigation--subNavigationActive {
        border-radius: 0;
      }

      .Polaris-Navigation__Item--selected {
        .Polaris-Navigation__Text {
          color: #202223;
          font-weight: 500;
        }

        .Polaris-Navigation__Icon svg *,
        .Polaris-Navigation__Badge svg * {
          fill: #3b1e33;
        }
      }
    }

    .Polaris-Navigation__SecondaryNavigation {
      .Polaris-Navigation__Item {
        .Polaris-Navigation__Text {
          font-weight: 400;
        }

        &:hover {
          border-radius: 0;
          & + a {
            background-color: #c4c4c419 !important;
          }
        }
      }
    }

    .Polaris-Navigation__Item--selected {
      background-color: transparent;

      .Polaris-Navigation__Icon svg *,
      .Polaris-Navigation__Badge svg * {
        fill: #3b1e33;
      }

      .Polaris-Navigation__Text {
        color: #202223;
      }
    }

    .Polaris-Navigation__ListItem:not(:first-child) {
      .Polaris-Navigation__Item {
        border-top: none;
      }
    }

    .smartrr-navigation-section-title {
      margin: 10px 0;
      color: #616161;
      padding: 0 10px;
      padding-left: 22px;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      margin-top: 46px;
    }

    .Polaris-Navigation__Text,
    .Polaris-Navigation__Icon {
      margin-top: 6px;
      margin-bottom: 6px;
    }

    .Polaris-Button {
      background-color: transparent;
      color: #202223;
    }

    & .Polaris-Navigation__Item--selected::before {
      background-color: transparent;
    }
  }

  .Polaris-Navigation__ListItem {
    background-color: transparent;
  }
`;

const TopBarContainer = styled.div`
  .Polaris-TopBar {
    background-color: #3b1e33;

    @media (min-width: 48rem) {
      .Polaris-TopBar__Container {
        grid-template-columns: 15rem minmax(auto, 36.25rem) 1fr;
      }

      .Polaris-TopBar__Search {
        justify-content: flex-start;
      }
    }

    .Polaris-TopBar-Menu__Activator {
      height: 32px;
      font-size: 13px;
      background-color: #e1e1e1;
      padding-left: 0px;

      .Polaris-TopBar-UserMenu__Details {
        padding-left: 8px;
      }
    }

    .Polaris-TopBar-Menu__Activator,
    .Polaris-TopBar-Menu__Activator:hover,
    .Polaris-TopBar-Menu__Activator:active,
    .Polaris-TopBar-Menu__Activator[aria-expanded="true"] {
      border-radius: 8px;
      padding-right: 0px;
    }

    .Polaris-TopBar-UserMenu__Details {
      color: #3b1e33;
    }

    .Polaris-TopBar-Menu__Activator > span {
      color: #202223;
    }

    .Polaris-TopBar-UserMenu__Detail {
      display: none;
    }

    .Polaris-TopBar-UserMenu__Details {
    }

    .Polaris-Avatar {
      background: #a7ebde;
      border-radius: 0.375rem;

      .Polaris-Avatar__Text {
        color: #245246;
      }
    }

    .Polaris-TopBar__SearchField {
      margin-left: 0;
      padding-left: 0;
    }

    .Polaris-TopBar__Search .Polaris-TopBar-Menu__Activator {
      background-color: transparent;
      fill: #ececec;

      & > span {
        color: #ececec;
        font-size: 13px;
        line-height: 20px;
      }
    }
  }
`;

type SelectedNavigationItem = "main" | "sub";
type NavigationSectionResult = ReturnType<typeof useFilteredNavigationSection>;

const useNavigationItemLabel = (navigationSections: NavigationSectionResult) => {
  return useMemo(() => {
    const defaultValue = { label: "" };
    const selectedNavigationElement =
      navigationSections
        .flatMap(section => {
          return section.items;
        })
        .filter(value => value.selected)
        .pop() || defaultValue;

    return selectedNavigationElement.label;
  }, [navigationSections]);
};

const NavigationMarkup = ({
  navigationSections,
  selectedNavigationItem,
}: {
  navigationSections: NavigationSectionResult;
  selectedNavigationItem: SelectedNavigationItem;
}) => {
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const isPlanActive = activePlan?.status === AccountPlanStatusEnum.ACTIVE;
  const { user } = useSmartrrVendorSelector(state => state.auth);
  const trialExpired = useHasTrialExpired();

  return (
    <NavigationContainer
      className={cx({
        "smartrr-navigation-main-item": selectedNavigationItem === "main",
        "smartrr-navigation-sub-item": selectedNavigationItem === "sub",
      })}
    >
      <Navigation location={adminRoutePrefix}>
        {navigationSections.map(({ title = "", separator, items }) => (
          <React.Fragment key={title || items[0]?.label}>
            {!!title && <span className="smartrr-navigation-section-title">{title}</span>}
            {!!separator && <hr />}
            {items.map(navItem => {
              const subNavItems = navItem.subNavigationItems.map(item => {
                return {
                  ...item,
                  ...(trialExpired
                    ? {
                        secondaryAction: {
                          icon: LockMinor,
                        },
                      }
                    : isPlanActive &&
                      !isFeatureAvailable(item.feature!, user, activePlan) && {
                        secondaryAction: {
                          icon: isFeatureAvailable(item.feature!, user, activePlan) ? null : LockMinor,
                        },
                      }),
                };
              });
              return (
                <Navigation.Item
                  key={navItem.label}
                  label={navItem.label}
                  icon={navItem.icon}
                  url={navItem.url}
                  selected={navItem.selected}
                  badge={navItem.locked ? <Icon source={LockMinor} /> : undefined}
                  subNavigationItems={subNavItems}
                />
              );
            })}
          </React.Fragment>
        ))}
      </Navigation>
    </NavigationContainer>
  );
};

const AdminAppFooter = () => {
  return (
    <div className="boring-footer-wrapper">
      <div className="boring-footer-container">
        <div className="copyright-container">{`Copyright © ${new Date().getFullYear()}. Smartrr Inc.`}</div>
        <div className="legal-links-container">
          <a
            className="legal-link-container"
            href={`https://${appPrefixedHost}/legal/tos.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            Terms of Service
          </a>
          <span className="legal-link-spacer">{"|"}</span>
          <a
            className="legal-link-container"
            href={`https://${appPrefixedHost}/legal/privacy.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            Privacy Policy
          </a>
        </div>
      </div>
    </div>
  );
};

const AdminTopBar = ({
  onNavigationToggle,
  onOrganizationSelectToggle,
}: {
  onNavigationToggle: () => void;
  onOrganizationSelectToggle: () => void;
}) => {
  const { user, auth_token } = useSmartrrVendorSelector(state => state.auth);
  const isSuperUser = useIsSuperUserSelector();
  const dispatch = useSmartrrVendorDispatch();
  const activeOrg = useActiveOrganizationSelector();

  const myShopifyDomain = useMyShopifyDomainSelector();

  const localStorageKey = useMemo(() => `${myShopifyDomain}:${user?.id}`, [myShopifyDomain, user]);

  const [isInMerchantView, setIsInMerchantView] = useState(isSuperUserSwitchDurationValid(localStorageKey));

  const [showingBadge, setShowingBadge] = useState(isInMerchantView || isSuperUser);
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const userDisplayName = useMemo(
    () =>
      [capitalize(user?.originalFirstName || ""), capitalize(user?.originalLastName || "")].join(" ") ||
      user?.originalEmail ||
      "",
    [user?.originalFirstName, user?.originalLastName, user?.originalEmail]
  );

  const orgDisplayName = useMemo(
    () => (activeOrg?.orgName || "").replaceAll(/-|_/g, " ").split(" ").map(capitalize).join(" "),
    [activeOrg?.orgName]
  );

  const updateSuperUserStatus = (isSuperUser: boolean) =>
    updateSuperUserStatusRedux(dispatch, isSuperUser, user!, auth_token!, activeOrg!);

  useEffect(() => {
    // If SuperUser is logging in and duration in localstorage is still valid,
    // set user to merchant view
    if (isInMerchantView && isSuperUser) {
      updateSuperUserStatus(false);
    }
  }, [isInMerchantView]);

  const toggleSuperUserStatus = () => {
    const localStorageEntry = getFromLocalStorage(localStorageKey);

    if (localStorageEntry) {
      unsetInLocalStorage(localStorageKey);
      setIsInMerchantView(false);
      dispatch(loadUser());
    } else {
      const duration = Date.now() + 10 * 60 * 1000; // 10 minutes in milliseconds
      const localStorageValue = JSON.stringify({
        duration,
      });

      setInLocalStorage(localStorageKey, localStorageValue); // storing state for future logins
      updateSuperUserStatus(false);
      setIsInMerchantView(true);
    }
  };

  return (
    <TopBarContainer>
      <TopBar
        showNavigationToggle
        onNavigationToggle={onNavigationToggle}
        searchField={
          <TopBar.Menu
            activatorContent={
              <React.Fragment>
                <span>{isInIFrame ? "Standalone mode" : orgDisplayName}</span>
                <Icon source={CaretDownMinor} />
              </React.Fragment>
            }
            open={false}
            onOpen={() => (isInIFrame ? doOpenStandaloneInNewTab() : onOrganizationSelectToggle())}
            onClose={() => undefined}
            actions={[]}
          />
        }
        userMenu={
          <React.Fragment>
            {showingBadge ? (
              <div
                onClick={toggleSuperUserStatus}
                style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
              >
                <SuperuserBadge merchantView={isInMerchantView} />
              </div>
            ) : null}

            <TopBar.UserMenu
              actions={[
                {
                  items: [
                    {
                      content: "Logout",
                      icon: CircleRightMajor,
                      onAction() {
                        void dispatch(logout());
                      },
                    },
                  ].concat(
                    isSuperUser || isInMerchantView
                      ? {
                          content: `${showingBadge ? "Hide" : "Show"} badge`,
                          icon: showingBadge ? HideMinor : ViewMajor,
                          onAction: () => setShowingBadge(prev => !prev),
                        }
                      : []
                  ),
                },
              ]}
              open={userMenuOpen}
              onToggle={() => setUserMenuOpen(open => !open)}
              name={userDisplayName}
              initials={userDisplayName
                .split(" ")
                .map(s => [s[0] ?? ""])
                .join("")}
            />
          </React.Fragment>
        }
      />
    </TopBarContainer>
  );
};

export function AdminLayoutAndNavigation({ children }: Props): JSX.Element {
  const organizationIsLoading = useSmartrrVendorSelector(state => state.vendorOrganizations.isLoading);

  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [selectOrganizationModalIsOpen, setSelectOrganizationModalIsOpen] = useState(false);

  const [selectedNavigationItem, setSelectedNavigationItem] = useState<SelectedNavigationItem>("main");

  const toggleOrgSelectModalOpen = useCallback(
    () => setSelectOrganizationModalIsOpen(!selectOrganizationModalIsOpen),
    [selectOrganizationModalIsOpen]
  );

  const navigationSections = useFilteredNavigationSection(
    () => {
      setMobileNavigationActive(active => (active ? false : active));
    },
    selected => {
      setSelectedNavigationItem(selected);
    }
  );

  const isAdminConfigRoute = isAdminConfig();

  const navItemLabelTitle = useNavigationItemLabel(navigationSections);

  const onNavigationToggle = useCallback(() => {
    setMobileNavigationActive(!mobileNavigationActive);
  }, [mobileNavigationActive]);

  useEffect(() => {
    document.title = `Smartrr | Admin | ${navItemLabelTitle}`;
  }, [navItemLabelTitle]);

  return (
    <React.Fragment>
      <div
        className={cx("frame-wrapper", "smartrr-use-inter", {
          isNonAdminConfigRoute: !isAdminConfigRoute,
        })}
      >
        <Frame
          navigation={
            <NavigationMarkup
              navigationSections={navigationSections}
              selectedNavigationItem={selectedNavigationItem}
            />
          }
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={useCallback(() => setMobileNavigationActive(false), [])}
          topBar={
            <AdminTopBar
              onNavigationToggle={onNavigationToggle}
              onOrganizationSelectToggle={toggleOrgSelectModalOpen}
            />
          }
          logo={{
            topBarSource: `//${appPrefixedHost}/smartrr-new.svg`,
            url: `//${appPrefixedHost}${adminRoutePrefix}`,
            accessibilityLabel: "smartrr",
            width: 132,
          }}
        >
          <div className="main-app-container-wrapper">
            <div className="main-app-container-container">
              {organizationIsLoading ? (
                <HorizontalStack align="center">
                  <Spinner />
                </HorizontalStack>
              ) : (
                children
              )}
            </div>
            <AdminAppFooter />
          </div>
        </Frame>
      </div>
      {!!selectOrganizationModalIsOpen && <SelectOrganizationModal onClose={toggleOrgSelectModalOpen} />}
    </React.Fragment>
  );
}
