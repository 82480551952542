import "./index.css";
import { Spinner } from "@shopify/polaris";
import React, { useEffect, useMemo, useRef } from "react";
import { loadUser } from "@vendor-app/app/_state/actionCreators/initialize";
import { useActiveOrganizationSelector } from "@vendor-app/app/_state/reducers/organizations";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { initPendo } from "@vendor-app/external/pendo";
import { useRequiredAuthRedirects } from "@vendor-app/utils/shopify/handleRequiredAuthRedirects";
import { useLDIdentityVendor } from "@vendor-app/launchdarkly";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import config from "@vendor-app/config";
import { LaunchDarklyOptions } from "@smartrr/shared/LaunchDarkly";
import { isEqual } from "lodash";

interface Props {
  children: React.JSX.Element;
}

export function AdminAuthContainer({ children: Component }: Props): JSX.Element {
  const user = useSmartrrVendorSelector(state => state.auth.user);
  const userIsLoading = useSmartrrVendorSelector(state => state.auth.isLoading);
  const { activePlan, isLoadingActivePlan } = useSmartrrVendorSelector(state => state.accountPlans);
  const activeOrg = useActiveOrganizationSelector();
  const dispatch = useSmartrrVendorDispatch();
  const areProductsLoading = useSmartrrVendorSelector(state => state.purchasables).isLoading;
  const isRedirecting = useRequiredAuthRedirects(userIsLoading, user);
  const LDProviderComponentRef = useRef<React.ComponentType<{}> | null>(null);

  const context = useLDIdentityVendor();
  const storedContext = useRef(context);

  useEffect(() => {
    if (!user && userIsLoading) {
      dispatch(loadUser());
    }
  }, []);

  useEffect(() => {
    if (!context || (isEqual(context, storedContext.current) && LDProviderComponentRef.current)) {
      return;
    }

    storedContext.current = context;
    LDProviderComponentRef.current = withLDProvider({
      context: storedContext.current!,
      clientSideID: config.launchDarkly.clientSideId,
      ...LaunchDarklyOptions,
    })(() => Component);
  }, [context]);

  useEffect(() => {
    if (!userIsLoading && !isRedirecting && activeOrg && user) {
      // pendo loaded into browser occurs in frontend/src/main
      initPendo({ authUser: user, activePlan, setup: activeOrg.setup, shopifyData: activeOrg.shopifyStoreData });
    }
  }, [userIsLoading, isRedirecting, activeOrg]);

  const LDProviderComponent = LDProviderComponentRef.current;

  const content = useMemo(() => {
    if (!LDProviderComponent) {
      return <React.Fragment>{Component}</React.Fragment>;
    }
    return <LDProviderComponent />;
  }, [LDProviderComponent]);

  if (isRedirecting || userIsLoading || isLoadingActivePlan || areProductsLoading) {
    return (
      <div className="loader-container">
        <Spinner />
      </div>
    );
  }

  return content;
}
