import { Outlet, createFileRoute } from "@tanstack/react-router";
import { ErrorBoundary } from "@vendor-app/app/_sharedComponents/ErrorBoundary";
import { LoadingToastProvider, ToastProvider } from "@vendor-app/app/_sharedComponents/Toast/ToastProvider";
import { AdminAuthContainer } from "@vendor-app/app/AdminRoute/components/auth/AdminAuthContainer";
import { ApolloProviderWrapper } from "@vendor-app/app/AdminRoute/components/auth/ApolloProviderWrapper";
import { RestProviderWrapper } from "@vendor-app/app/AdminRoute/components/auth/RestProviderWrapper";
import { AdminLayoutContainer } from "@vendor-app/app/AdminRoute/components/layout/AdminLayoutContainer";

export const Route = createFileRoute("/admin")({
  component: Index,
  errorComponent: ({ error, info }) => <ErrorBoundary error={error} info={info} />,
});

function Content() {
  return (
    <div className="app-content">
      <Outlet />
    </div>
  );
}

function Index() {
  return (
    <AdminAuthContainer>
      <ApolloProviderWrapper>
        <RestProviderWrapper>
          <AdminLayoutContainer>
            <ToastProvider>
              <LoadingToastProvider>
                <Content />
              </LoadingToastProvider>
            </ToastProvider>
          </AdminLayoutContainer>
        </RestProviderWrapper>
      </ApolloProviderWrapper>
    </AdminAuthContainer>
  );
}
