import { useMemo } from "react";
import { useMediaQuery } from "react-responsive";
import { MediaMinWidths } from "@smartrr/shared/components/primitives";
import { useNavigationSections } from "../useNavigationsSections.tsx";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useHasTrialExpired } from "@vendor-app/utils/useHasTrialExpired";
import { useMatches } from "@tanstack/react-router";
import { trimEnd } from "lodash";
import { adminRoutePrefix } from "@smartrr/shared/constants.js";

import { SectionStructure, SubNavigationStructure } from "../../../AdminLayoutAndNavigation.js";
import { FeatureEnum } from "@smartrr/shared/entities/AccountPlan.js";
import { isFeatureAvailable } from "@vendor-app/app/AdminRoute/components/authorization/featureAccess.js";

type SelectedNavigationItem = "main" | "sub";

export const useFilteredNavigationSection = (
  onNavigationItemClick: () => void,
  onSelectedItemUpdate: (selected: SelectedNavigationItem) => void
) => {
  const isMobile = !useMediaQuery({ minWidth: MediaMinWidths.Medium });
  const navigationSectionsStructure = useNavigationSections();
  const { user } = useSmartrrVendorSelector(state => state.auth);
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const trialExpired = useHasTrialExpired();
  const { pathname } = useMatches().at(-1)!;
  const cleanedWindowPath = trimEnd(pathname, "/");

  return useMemo(() => {
    // Filter sections that contain only items available to current user per active account plan
    const availableSections: SectionStructure[] = [];
    for (const section of navigationSectionsStructure) {
      const getFilteredSections = () => {
        if (trialExpired && !user?.isSuperUser) {
          return section.items.filter(item => item.feature !== FeatureEnum.SHOPIFY_DEBUG);
        }
        switch (activePlan?.planName) {
          case "Free Trial": {
            return section.items.filter(item =>
              item.feature && item.feature !== FeatureEnum.ANALYTICS && item.feature !== FeatureEnum.LOYALTY
                ? isFeatureAvailable(item.feature!, user, activePlan)
                : true
            );
          }
          case "Launch": {
            return section.items.filter(item =>
              item.feature &&
              item.feature !== FeatureEnum.LOYALTY &&
              item.feature !== FeatureEnum.INTEGRATIONS &&
              item.feature !== FeatureEnum.TRANSLATIONS
                ? isFeatureAvailable(item.feature!, user, activePlan)
                : true
            );
          }
          case "Grow": {
            return section.items.filter(item =>
              item.feature && item.feature !== FeatureEnum.LOYALTY
                ? isFeatureAvailable(item.feature!, user, activePlan)
                : true
            );
          }
          default: {
            return section.items.filter(item =>
              item.feature ? isFeatureAvailable(item.feature!, user, activePlan) : true
            );
          }
        }
      };

      let availableItems: ReturnType<typeof getFilteredSections> = [];

      const filteredSectionItems = getFilteredSections();

      for (const item of filteredSectionItems!) {
        const subNavigationItems = item?.subNavigationItems || [];

        availableItems.push({
          ...item,
          subNavigationItems,
        });
      }

      availableItems = availableItems.map(item => {
        if (item.feature) {
          if (!isFeatureAvailable(item.feature, user, activePlan)) {
            item.locked = true;
          }
        }
        return item;
      });

      // Adding this here as it will overwrite the
      // previous values if conditions are true
      if (trialExpired && !user?.isSuperUser) {
        availableItems = availableItems.map(item => {
          if (item.label === "Account" || item.label === "Help") {
            item.locked = false;
          } else {
            item.locked = true;
          }
          return item;
        });
      }
      if (filteredSectionItems && filteredSectionItems.length > 0) {
        availableSections.push({
          ...section,
          items: availableItems,
        });
      }
    }

    let isSubnavigationSelected = false;
    // Convert sections structure to props that reflect current page and are bound to callbacks
    const sectionList = availableSections.map(({ title, separator, items }) => ({
      title,
      separator,
      items: items.map(navItem => {
        let subNavigationItems: SubNavigationStructure[] = [];
        const cleanedNavPath = trimEnd(navItem.url ? navItem.url.split("?")[0] : "", "/");

        // This handles the case when the router automatically navigates us to default route: when we are on url
        // "/admin" route (adminRoutePrefix), this url doesn't match any real route in the application, so router
        // redirects us to default route. With the hack below we make sure that the default route appears as
        // selected in the navigation menu
        const isOnBasePath = navItem.isDefault && trimEnd(adminRoutePrefix) === cleanedWindowPath;

        if (navItem.subNavigationItems?.length) {
          subNavigationItems = navItem.subNavigationItems.map(subNavItem => {
            const cleanedSubNavPath = trimEnd(subNavItem.url ? subNavItem.url.split("?")[0] : "", "/");
            const isSelected =
              typeof window === "undefined" ? false : cleanedWindowPath.startsWith(cleanedSubNavPath);
            if (isSelected) {
              isSubnavigationSelected = true;
            }
            return {
              ...subNavItem,
              onClick() {
                onNavigationItemClick();
              },
              selected: isSelected,
            };
          });
        }

        return {
          ...navItem,
          subNavigationItems,
          onClick() {
            if (!navItem.subNavigationItems?.length) {
              onNavigationItemClick();
            }
          },
          selected:
            typeof window === "undefined"
              ? false
              : cleanedWindowPath.startsWith(cleanedNavPath) ||
                isOnBasePath ||
                !!navItem.subNavigationItems?.find(subNavItem => {
                  const cleanedSubNavPath = trimEnd(subNavItem.url ? subNavItem.url.split("?")[0] : "", "/");
                  return cleanedWindowPath.startsWith(cleanedSubNavPath);
                }),
        };
      }),
    }));

    onSelectedItemUpdate(isSubnavigationSelected ? "sub" : "main");
    return sectionList;
  }, [cleanedWindowPath, user, activePlan, isMobile]);
};
