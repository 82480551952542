import { useMemo } from "react";

import { useMyShopifyDomainSelector } from "@vendor-app/app/_state/reducers/shopify";
import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useIsSuperUserSelector } from "@vendor-app/app/_state/reducers/auth";

import { AccountPlanStatusEnum, FeatureEnum } from "@smartrr/shared/entities/AccountPlan";
import { adminRoutePrefix, adminConfigRoutePrefix, shopUrlPropertyName } from "@smartrr/shared/constants";
import { stringify } from "qs";

import { NavigationHomeIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Home";
import { NavigationSubscriptionIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Subscription";
import { NavigationCustomerIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Customer";
import { NavigationReportIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Report";
import { NavigationSubscriptionProgramIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/SubscriptionProgram";
import { NavigationLoyaltyIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Loyalty";
import { NavigationThemingIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Theming";
import { NavigationTranslationsIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Translations";
import { NavigationIntegrationsIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Integrations";
import { NavigationAccountIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Account";
import { NavigationNotificationsIcon } from "@vendor-app/app/AdminRoute/components/layout/icons/navigation/Notifications";

import { QuestionMarkInverseMajor } from "@shopify/polaris-icons";

import { SectionStructure } from "@vendor-app/app/AdminRoute/components/layout/AdminLayoutAndNavigation";

export const useNavigationSections = (): SectionStructure[] => {
  const myShopifyDomain = useMyShopifyDomainSelector();
  const activePlan = useSmartrrVendorSelector(state => state.accountPlans.activePlan);
  const isPlanActive = activePlan?.status === AccountPlanStatusEnum.ACTIVE;
  const isSuperUser = useIsSuperUserSelector();

  return useMemo((): SectionStructure[] => {
    const queryString = stringify(
      {
        [shopUrlPropertyName]: myShopifyDomain,
      },
      {
        addQueryPrefix: true,
      }
    );
    return [
      {
        items: [
          {
            label: "Home",
            icon: NavigationHomeIcon,
            url: `${adminRoutePrefix}/home${queryString}`,
            feature: FeatureEnum.SHOPIFY_DEBUG,
          },
          {
            label: "Subscriptions",
            icon: NavigationSubscriptionIcon,
            url: `${adminRoutePrefix}/subscriptions${queryString}`,
            feature: FeatureEnum.CORE_SUBSCRIPTIONS,
            isDefault: !!activePlan,
            subNavigationItems: [
              {
                label: "Orders",
                url: `${adminRoutePrefix}/orders${queryString}`,
                feature: FeatureEnum.CORE_SUBSCRIPTIONS,
              },
              {
                label: "Transactions",
                url: `${adminRoutePrefix}/bills${queryString}`,
                feature: FeatureEnum.CORE_SUBSCRIPTIONS,
              },
            ],
          },
          {
            label: "Customers",
            icon: NavigationCustomerIcon,
            url: `${adminRoutePrefix}/customers${queryString}`,
            feature: FeatureEnum.CORE_SUBSCRIPTIONS,
          },
          {
            label: "Reports",
            icon: NavigationReportIcon,
            url: `${adminRoutePrefix}/analytics${queryString}`,
            feature: FeatureEnum.ANALYTICS,
          },
          {
            label: "Advanced Analytics",
            icon: NavigationReportIcon,
            url: `${adminRoutePrefix}/advanced-analytics${queryString}`,
            feature: FeatureEnum.LOOKER_ANALYTICS,
          },
        ],
      },
      {
        title: "Configuration",
        items: [
          {
            label: "Subscription Programs",
            icon: NavigationSubscriptionProgramIcon,
            url: `${adminConfigRoutePrefix}/plans${queryString}`,
            feature: FeatureEnum.CORE_CONFIGURATION,
            subNavigationItems: [
              {
                label: "Shipping",
                url: `${adminConfigRoutePrefix}/delivery-profiles${queryString}`,
                feature: FeatureEnum.CORE_CONFIGURATION,
              },
              {
                label: "Transaction Settings",
                url: `${adminConfigRoutePrefix}/billing-settings${queryString}`,
                feature: FeatureEnum.CORE_CONFIGURATION,
              },
              {
                label: "Retention",
                url: `${adminConfigRoutePrefix}/churn-prevention${queryString}`,
                feature: FeatureEnum.RETENTION,
              },
              {
                label: "Bundles",
                url: `${adminConfigRoutePrefix}/bundles${queryString}`,
                feature: FeatureEnum.BUNDLES,
              },
            ],
          },
          {
            label: "Loyalty",
            icon: NavigationLoyaltyIcon,
            url: `${adminRoutePrefix}/loyalty${queryString}`,
            feature: FeatureEnum.LOYALTY,
          },
          {
            label: "Customer Notifications",
            icon: NavigationNotificationsIcon,
            url: `${adminConfigRoutePrefix}/subscriber-engagement${queryString}`,
            feature: FeatureEnum.CORE_CONFIGURATION,
          },
          {
            label: "Theming",
            icon: NavigationThemingIcon,
            url: `${adminConfigRoutePrefix}/theming${queryString}`,
            feature: FeatureEnum.CORE_CONFIGURATION,
          },
          {
            label: "Translations",
            icon: NavigationTranslationsIcon,
            url: `${adminConfigRoutePrefix}/translations${queryString}`,
            feature: FeatureEnum.TRANSLATIONS,
          },
          {
            label: "Integrations",
            icon: NavigationIntegrationsIcon,
            url: `${adminConfigRoutePrefix}/integrations${queryString}`,
            feature: FeatureEnum.INTEGRATIONS,
          },
        ],
      },
      {
        title: "Resources",
        items: [
          {
            label: "Account",
            icon: NavigationAccountIcon,
            isDefault: !activePlan, // TODO: Set is default flag to homepage after release
            url: `${adminRoutePrefix}/smartrr-account${queryString}`,
            subNavigationItems: [
              ...(isPlanActive || isSuperUser
                ? [
                    {
                      label: "Setup",
                      url: `${adminRoutePrefix}/setup${queryString}`,
                      feature: FeatureEnum.SETUP,
                    },
                  ]
                : []),
              ...(isSuperUser
                ? [
                    {
                      label: "Migrations",
                      url: `${adminConfigRoutePrefix}/migrations${queryString}`,
                    },
                  ]
                : []),
              ...(isSuperUser
                ? [
                    {
                      label: "Super User Admin",
                      url: `${adminRoutePrefix}/super-user${queryString}`,
                    },
                  ]
                : []),
            ],
          },
          {
            label: "Help",
            icon: QuestionMarkInverseMajor,
            url: `${adminRoutePrefix}/support${queryString}`,
            feature: FeatureEnum.SETUP,
          },
        ],
      },
    ];
  }, [myShopifyDomain, activePlan]);
};
